
import {map,  catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';

// import { Http, Response, URLSearchParams, RequestOptions,Headers } from '@angular/http';
import { Observable ,  Unsubscribable as AnonymousSubscription  } from 'rxjs';
import { ChatUser } from '../model/chatuser.model';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Voucher } from '../model/voucher.model';
import { GenericSelectItem } from '../model/genericselectitem.model';
import { ChatService } from './chatservice';
import { ClientCourse } from '../model/clientcourse.model';
import { AppConfig } from '../AppConfig';
import { UserRole } from '../model/UserRole.model';
import { MaintenanceMode } from '../model/maintenancemode.model';
import { CannedResponse } from '../model/cannedresponse.model';

@Injectable()
export class AdminService {
  
  constructor(private _httpClient: HttpClient, private chatService:ChatService, private config: AppConfig) {

  }

//   CountUsers(searchCriteria:string, countMentors:boolean, countPendingActivation: boolean, countAtDownState: boolean) {
//     return this._http.get(this.config.origin + "/api/admin/countusers?searchCriteria=" + searchCriteria + "&countMentors=" + countMentors + "&countPendingActivation=" + countPendingActivation + "&countAtDownState=" + countAtDownState)
//         .map(responseData => responseData.json());
//     }

CountUsers(searchCriteria:string, countMentors:boolean, countPendingActivation: boolean, countAtDownState: boolean) {
    return this._httpClient.get(this.config.origin + "/api/admin/countusers?searchCriteria=" + searchCriteria + "&countMentors=" + countMentors + "&countPendingActivation=" + countPendingActivation + "&countAtDownState=" + countAtDownState).pipe(
        map((responseData: any) =>{ 
            return responseData;
        }));
  }

//     GetUsers(pageNumber:Number, pageSize:Number, searchCriteria:string, mentorsFlag:boolean, pendingActivationFilter: boolean, atDownStateFilter: boolean): Observable<ChatUser[]> {
//         return this._http.get(this.config.origin + "/api/admin/getusers?pageNumber=" + pageNumber + "&pageSize="+ pageSize + "&searchCriteria="+searchCriteria+ "&mentorsFlag=" + mentorsFlag + "&pendingActivationFilter=" + pendingActivationFilter + "&atDownStateFilter=" + atDownStateFilter)
//             .map((response: Response) => {
//                 if (response.text().length > 0){
//                     return (response.json()).map(item => {
//                         return new ChatUser({
//                             id: item.Id,
//                             name: item.Name,
//                             email: item.Email,
//                             logo: item.Logo,
//                             isBanned: item.IsBanned
//                         });
//                     });
//                 }
//             });
//     }


  GetUsers(pageNumber:Number, pageSize:Number, searchCriteria:string, mentorsFlag:boolean, pendingActivationFilter: boolean, atDownStateFilter: boolean): Observable<ChatUser[]> {
        return this._httpClient.get(this.config.origin + "/api/admin/getusers?pageNumber=" + pageNumber + "&pageSize="+ pageSize + "&searchCriteria="+searchCriteria+ "&mentorsFlag=" + mentorsFlag + "&pendingActivationFilter=" + pendingActivationFilter + "&atDownStateFilter=" + atDownStateFilter).pipe(
            map((response: any) => {
                if (response != null && response.length > 0){
                    return (response).map(item => {
                        return new ChatUser({
                            id: item.Id,
                            name: item.Name,
                            email: item.Email,
                            logo: item.Logo,
                            isBanned: item.IsBanned,
                            role : item.Role
                        });
                    });
                }else{
                    alert("No results satisfy the search criteria!");
                    var arr: ChatUser[] = [];
                    return arr;
                }
            }));
    }

    GetChatUsers(pageNumber:Number, pageSize:Number, searchCriteria:string, mentorsFlag:boolean, pendingActivationFilter: boolean, atDownStateFilter: boolean): Observable<ChatUser[]> {
        return this._httpClient.get(this.config.origin + "/api/admin/getchatusers?pageNumber=" + pageNumber + "&pageSize="+ pageSize + "&searchCriteria="+searchCriteria+ "&mentorsFlag=" + mentorsFlag + "&pendingActivationFilter=" + pendingActivationFilter + "&atDownStateFilter=" + atDownStateFilter).pipe(
            map((response: any) => {
                if (response != null && response.length > 0){
                    return (response).map(item => {
                        return new ChatUser({
                            id: item.Id,
                            name: item.Name,
                            email: item.Email,
                            logo: item.Logo,
                            isBanned: item.IsBanned,
                            role : item.Role
                        });
                    });
                }else{
                    alert("No results satisfy the search criteria!");
                    var arr: ChatUser[] = [];
                    return arr;
                }
            }));
    }

    UploadLogo(formData:FormData){
        return this._httpClient.post(this.config.origin + "/api/admin/uploadlogo", formData, {responseType: 'text'});
    }

    // UpdateUser(chatUser:ChatUser) { 
    //     return this._http.post('/api/admin/updateuser', JSON.stringify(chatUser), this.GetRequestOptions());
    // }

    UpdateUser(chatUser:ChatUser) { 
        return this._httpClient
        .post(this.config.origin + '/api/admin/updateuser', JSON.stringify(chatUser),{responseType: 'text'});  
    }

    // GetUserDetails(id:string){
    //     return this._http.get(this.config.origin + "/api/admin/getuserdetails?id="+id)
    //     .map((response: Response) => {
    //         if (response.text().length > 0){
    //             return (response.json()).map(item => {
    //                 return ChatUser.createClient(item);
    //             });
    //         }
    //     });
    // }

    GetUserDetails(id:string){
        return this._httpClient.get(this.config.origin + "/api/admin/getuserdetails?id="+id).pipe(
        map((response: any) => {
            if (response.length > 0){
                return (response).map(item => {
                    return ChatUser.createClient(item);
                });
            }
        }));
    }

    GetUserRoles(){
        return this._httpClient.get(this.config.origin + "/api/admin/getuserroles").pipe(
        map((response: any) => {
            if (response.length > 0){
                
                let roles: UserRole[] = new Array<UserRole>();
                response.forEach(element => {
                        roles.push(UserRole.createRole(element)); 
                        }); 
                    return roles;
                
            }
        }));
    }

    // UpdatePassword(data:any) {
    //     return this._http.post('/api/admin/updatepassword', JSON.stringify(data), this.GetRequestOptions());
    // }

    UpdatePassword(data:any) {
        return this._httpClient.post(this.config.origin  + '/api/admin/updatepassword', JSON.stringify(data), {responseType: 'text'});
    }

    // CreateUser(chatUser:ChatUser) {  
    //     return this._http.post('/api/admin/createuser', JSON.stringify(chatUser), this.GetRequestOptions());
    // }

    CreateUser(chatUser:ChatUser) {  
        return this._httpClient.post(this.config.origin + '/api/admin/createuser', JSON.stringify(chatUser) ,{responseType: 'text'});
    }

    // ReInviteUser(chatUser:ChatUser) {  
    //     return this._http.post('/api/admin/reinviteuser', JSON.stringify(chatUser), this.GetRequestOptions());
    // }

    ReInviteUser(chatUser:ChatUser) {  
        return this._httpClient.post(this.config.origin  + '/api/admin/reinviteuser', JSON.stringify(chatUser), {responseType: 'text'});
    }

    // ValidateUserToken(userId:string, token:string){
    //     return this._http.get('/api/admin/validateusertoken?userid='+userId + "&token=" + token);
    // }

    ValidateUserToken(userId:string, token:string){
        return this._httpClient.get(this.config.origin + '/api/admin/validateusertoken?userid='+userId + "&token=" + token,{responseType: 'text'}).pipe(
        map((response: any) => {
            return response;
        }));
    }

    // UpdateMentorPassword(data:any){
    //     return this._http.post('/api/admin/updatementorpassword',data,this.GetRequestOptions());
    // }

    UpdateMentorPassword(data:any){
        return this._httpClient.post(this.config.origin  + '/api/admin/updatementorpassword',data, {responseType: 'text'}).pipe(
        map((response: any) => {
            return response;
        }));
    }

    // private GetRequestOptions(){
    //     let headers = new Headers();
    //     headers.append('Content-Type', 'application/json');
    //     return new RequestOptions({ headers: headers }); 
    // }

    private GetRequestHeaders(){
        let headers = new HttpHeaders();
        headers.append('Content-Type', 'application/json');
        return headers;
    }

    // RegisterUserInAt(chatUser: any){
    // //   return this._http.post('/api/admin/registertome', this.GetRequestOptions());
    // let headers = new Headers()  
    //     let options = new RequestOptions({ headers: headers });  
    //     // let response = this._http.post('/api/Account/RegisterClientInAt?chatUserId='+chatUser.id,options).subscribe(
    //     return this._http.post('/api/Account/RegisterClientInAt?chatUserId='+chatUser.id,options);
    // //     .subscribe(
    // //     res => {
    // //       console.log(res);
    // //     },
    // //     (err: any) => {
    // //       console.log(err.error);
    // //       console.log(err.name);
    // //       console.log(err.message);
    // //       console.log(err.status);
    // //     }
    // //   );

    // //   return response;
    // }


    RegisterUserInAt(chatUser: any){
            return this._httpClient.post(this.config.origin  + '/api/Account/RegisterClientInAt?chatUserId='+chatUser.id, {responseType: 'text'});
        }

    // CancelRegistration(chatUser: any){
    //     let headers = new Headers()  
    //     let options = new RequestOptions({ headers: headers });  
    //     let response = this._http.delete('/api/Account/AtDownUserCancelRegistration?chatUserId='+chatUser.id,options).subscribe(
    //     res => {
    //       console.log(res);
    //     },
    //     (err: any) => {
    //       console.log(err.error);
    //       console.log(err.name);
    //       console.log(err.message);
    //       console.log(err.status);
    //     }
    //   );

    //   return response;
    // }

    CancelRegistration(chatUser: any){
        let response = this._httpClient.delete(this.config.origin + '/api/Account/AtDownUserCancelRegistration?chatUserId='+chatUser.id, {responseType: 'text'}).subscribe(
        res => {
          console.log(res);
        },
        (err: any) => {
          console.log(err.error);
          console.log(err.name);
          console.log(err.message);
          console.log(err.status);
        }
      );

      return response;
    }


    // ActivateUser(chatUser: any){
    //     let headers = new Headers()  
    //     let options = new RequestOptions({ headers: headers });  
    //     return this._http.post('/api/Account/ActivateUser?chatUserId='+chatUser.id,options);
    // }

    ActivateUser(chatUser: any){
        return this._httpClient.post(this.config.origin  + '/api/Account/ActivateUser?chatUserId='+chatUser.id, {headers: this.GetRequestHeaders()});
    }


    // CountVouchers(searchCriteria:string) {
    //     return this._http.get(this.config.origin + "/api/admin/countvouchers?searchCriteria=" + searchCriteria)
    //         .map(responseData => responseData.json());
    // }

    CountVouchers(searchCriteria:string) {
        return this._httpClient.get(this.config.origin + "/api/admin/countvouchers?searchCriteria=" + searchCriteria,{responseType: 'text'}).pipe(
        map((responseData: any) =>{ 
            return responseData;
        }));
    }

    // GetVouchers(pageNumber:Number, pageSize:Number, searchCriteria:string): Observable<Voucher[]> {
    //     return this._http.get(this.config.origin + "/api/admin/getvouchers?pageNumber=" + pageNumber + "&pageSize="+ pageSize + "&searchCriteria="+searchCriteria)
    //         .map((response: Response) => {
    //             if (response.text().length > 0){
    //                 return (response.json()).map(item => {
    //                     return new Voucher({
    //                         voucherId: item.VoucherId,
    //                         clientName: item.ClientName,
    //                         redeemedByUser: item.RedeemedByUser,
    //                         validFor: item.ValidFor,
    //                         expiryDate: new Date(item.ExpiryDate).toLocaleDateString("en-US")
    //                     });
    //                 });
    //             }
    //         });
    // }

    GetVouchers(pageNumber:Number, pageSize:Number, searchCriteria:string): Observable<Voucher[]> {
        return this._httpClient.get(this.config.origin + "/api/admin/getvouchers?pageNumber=" + pageNumber + "&pageSize="+ pageSize + "&searchCriteria="+searchCriteria).pipe(
            map((response: any) => {
                if (response.length > 0){
                    return (response).map(item => {
                        return new Voucher({
                            voucherId: item.VoucherId,
                            clientName: item.ClientName,
                            redeemedByUser: item.RedeemedByUser,
                            validFor: item.ValidFor,
                            expiryDate: new Date(item.ExpiryDate).toLocaleDateString("en-US")
                        });
                    });
                }
            }));
    }


    // GetVoucherDetails(voucherId:string){
    //     return this._http.get(this.config.origin + "/api/admin/getvoucherdetails?voucherId="+voucherId)
    //     .map((response: Response) => {
    //         if (response.text().length > 0){
    //             return (response.json()).map(item => {
    //                 return new Voucher({
    //                     voucherId: item.VoucherId,
    //                     clientName: item.ClientName,
    //                     redeemedByUser: item.RedeemedByUser,
    //                     validFor: item.ValidFor,
    //                     expiryDate: new Date(item.ExpiryDate).toLocaleDateString("en-US"),
    //                     courses:item.Courses,
    //                     type:item.Type
    //                 });
    //             });
    //         }
    //     });
    // }

    GetVoucherDetails(voucherId:string){
        return this._httpClient.get(this.config.origin + "/api/admin/getvoucherdetails?voucherId="+voucherId).pipe(
        map((response: any) => {
            if (response.length > 0){
                return (response).map(item => {
                    return new Voucher({
                        voucherId: item.VoucherId,
                        clientName: item.ClientName,
                        redeemedByUser: item.RedeemedByUser,
                        validFor: item.ValidFor,
                        expiryDate: new Date(item.ExpiryDate).toLocaleDateString("en-US"),
                        courses:item.Courses,
                        type:item.Type
                    });
                });
            }
        }));
    }

    // UpdateVoucher(voucher:Voucher) { 
    //     return this._http.post('/api/admin/updatevoucher', JSON.stringify(voucher), this.GetRequestOptions());
    // }

    UpdateVoucher(voucher:Voucher) { 
        return this._httpClient.post(this.config.origin  + '/api/admin/updatevoucher', JSON.stringify(voucher), {responseType: 'text'}).pipe(
        map((response: any) => {
            return response;
        }));
    }

    // CreateVoucher(voucher:Voucher, noOfVouchers:Number, selectedCourses: string) {  
    //     return this._http.post('/api/admin/createvoucher?noOfVouchers='+noOfVouchers+"&selectedCourses="+selectedCourses, JSON.stringify(voucher), this.GetRequestOptions());
    // }

    CreateVoucher(voucher:Voucher, noOfVouchers:Number, selectedCourses: string) {  
        return this._httpClient.post(this.config.origin + '/api/admin/createvoucher?noOfVouchers='+noOfVouchers+"&selectedCourses="+selectedCourses, JSON.stringify(voucher), {responseType: 'text'}).pipe(
        map((response: any) => {
            return response;
        }));
    }


    // GetPartners(type:any) {
    //     return this._http.get(this.config.origin + "/api/admin/getpartners?type="+ type)
    //     .map((response: Response) => {
    //         if (response.text().length > 0){
    //             return (response.json()).map(item => {
    //                 return GenericSelectItem.Create(item,true);
    //             });
    //         }
    //     });
    // }

    GetPartners(type:any) {
        return this._httpClient.get(this.config.origin + "/api/admin/getpartners?type="+ type).pipe(
        map((response: any) => {
            if (response.length > 0){
                return (response).map(item => {
                    return GenericSelectItem.Create(item,true);
                });
            }
        }));
    }

    // GetChildClients(partnerId:any) {
    //     return this._http.get(this.config.origin + "/api/admin/getclientsbypartner?partnerId=" + partnerId)
    //     .map((response: Response) => {
    //         if (response.text().length > 0){
    //             return (response.json()).map(item => {
    //                 return GenericSelectItem.Create(item,true);
    //             });
    //         }
    //     });
    // }


    GetChildClients(partnerId:any) {
        return this._httpClient.get(this.config.origin + "/api/admin/getclientsbypartner?partnerId=" + partnerId).pipe(
        map((response: any) => {
            if (response.length > 0){
                return (response).map(item => {
                    return GenericSelectItem.Create(item,true);
                });
            }
        }));
    }

    // CheckActiveSessions(userName: string, deviceType: string) {
    //     return this._http.get("api/admin/checkactivesessions?userName=" + userName + "&deviceType=" + deviceType)
    //     .map((response: Response) => {
    //         return response;
    //     });
    // }

    CheckActiveSessions(userName: string, deviceType: string) {
        return this._httpClient.get(this.config.origin + "api/admin/checkactivesessions?userName=" + userName + "&deviceType=" + deviceType, {responseType: 'text'}).pipe(
        map((response: any) => {
            return response;
        }));
    }

    // ClearActiveSessions(userId: string){
    //     return this._http.get("api/admin/clearactivesessions?userId=" + userId)
    //     .map((response: Response) => {
    //         return response;
    //     });
    // }

    ClearActiveSessions(userId: string){
        return this._httpClient.get(this.config.origin + "/api/admin/clearactivesessions?userId=" + userId, {responseType: 'text'}).pipe(
        map((response: any) => {
            return response;
        }));
    }

    DeactivateUser(userId: string){
        return this._httpClient.get(this.config.origin + "/api/admin/deactivateuser?userId=" + userId, {responseType: 'text'}).pipe(
        map((response: any) => {
            return response;
        }));
    }

    // UnbanUser(userId:string){
    //     return this._http.post('/api/admin/unbanuser',JSON.stringify(userId),this.GetRequestOptions());
    // }

    UnbanUser(userId:string){
        return this._httpClient.post(this.config.origin + '/api/admin/unbanuser',JSON.stringify(userId), {responseType: 'text'}).pipe(
        map((response: any) => {
            return response;
        }));
    }

    // GetCoursesForClient(clientId: Number){
    //     return this._http.get(this.config.origin + "/api/admin/getcoursesforclient?clientId="+clientId)
    //     .map((response: Response) => {
    //         if (response.text().length > 0){
    //             return (response.json()).map(item => {
    //                 return new ClientCourse({
    //                     clientCourseId: item.Key,
    //                     name: item.Name,
    //                     code: item.Code
    //                 });
    //             });
    //         }
    //     });
    // }

    GetCoursesForClient(clientId: Number){
        return this._httpClient.get(this.config.origin + "/api/admin/getcoursesforclient?clientId="+clientId).pipe(
        map((response: any) => {
            if (response.length > 0){
                return (response).map(item => {
                    return new ClientCourse({
                        clientCourseId: item.Key,
                        name: item.Name,
                        code: item.Code
                    });
                });
            }
        }));
    }

    // GetAppVersion(){
    //     return this._http.get(this.config.origin + '/api/Account/Version').map(res => res);
    // }

    GetAppVersion(){
        return this._httpClient.get(this.config.origin + '/api/Account/Version',{responseType: 'text'}).pipe(map(res => res));
    }

    // DoEmptyRequest(){
    //     return this._http.get('/api/Account/Empty').map(res => res);
    // }

    DoEmptyRequest(){
        return this._httpClient.get(this.config.origin + '/api/Account/Empty').pipe(map(res => res));
    }
    

    CheckIfMaintenanceModeIsEnabled(groupId: number){
        return this._httpClient.get(this.config.origin + "/api/admin/maintenancemode?groupId="+groupId).pipe(
        map((response: any) => {
            if (response.length > 0){
                return (response).map(item => {
                    return new MaintenanceMode({
                        groupId: item.GroupId,
                        loginAllowed: item.LoginAllowed,
                        greetingMessage: item.GreetingMessage
                    });
                });
            }
        }));
    }

    GetCannedResponses(pageNumber:Number, pageSize:Number, searchCriteria:string): Observable<CannedResponse[]> {
        return this._httpClient.get(this.config.origin + "/api/admin/getcannedresponses?pageNumber=" + pageNumber + "&pageSize="+ pageSize + "&searchCriteria="+searchCriteria).pipe(
            map((response: any) => {
                if (response != null && response.length > 0){
                    return (response).map(item => {
                        return new CannedResponse({
                            id: item.Id,
                            name: item.Name,
                            description: item.Description,
                            text: item.Text,
                            tags: item.Tags
                        });
                    });
                }else{
                    alert("No results satisfy the search criteria!");
                    var arr: CannedResponse[] = [];
                    return arr;
                }
            }));
    }

    CountCannedResponses(searchCriteria:string) {
        return this._httpClient.get(this.config.origin + "/api/admin/countcannedresponses?searchCriteria=" + searchCriteria).pipe(
            map((responseData: any) =>{ 
                return responseData;
            }));
      }

      DeleteCannedResponse(cannedResponseId: string){
        return this._httpClient.get(this.config.origin + "/api/admin/deletecannedresponse?id=" + cannedResponseId, {responseType: 'text'}).pipe(
        map((response: any) => {
            return response;
        }));
    }

    GetCannedResponseById(id:string){
        return this._httpClient.get(this.config.origin + "/api/admin/getcannedresponse?id="+id).pipe(
        map((response: any) => {
            if (response.length > 0){
                return (response).map(item => {
                    return CannedResponse.Create(item);
                });
            }
        }));
    }

    CreateCannedResponse(cannedResponse:CannedResponse) {  
        return this._httpClient
        .post(this.config.origin + '/api/admin/createcannedresponse', JSON.stringify(cannedResponse) ,{responseType: 'text'});
    }

    UpdateCannedResponse(cannedResponse:CannedResponse) { 
        return this._httpClient
        .post(this.config.origin + '/api/admin/updatecannedresponse', JSON.stringify(cannedResponse),{responseType: 'text'});  
    }
}
