<br /><br />

<form *ngIf="cannedResponse" class="example-form" name="form" (ngSubmit)="disableSaveButton || onSaveClick(myform.value)" [formGroup]="myform">

    <mat-form-field class="example-full-width" *ngIf="cannedResponse.id != '-1'">
        <input style="font-weight: bold;" matInput disabled placeholder="ID" value="{{cannedResponse.id}}">
    </mat-form-field>
    
    <div class="form-group">
      <mat-form-field class="example-full-width">
        <input matInput placeholder="Description" formControlName="description" >
      </mat-form-field>
      <div class="validation-errors">
        <div class="error-message" *ngIf="myform.get('description').hasError('required') && (myform.get('description').dirty || myform.get('description').touched)">Description is required field!</div>
      </div>
    </div>

    <div class="form-group">
      <mat-form-field class="example-full-width">
          <textarea matInput placeholder="Text" formControlName="responseText" required rows="5"></textarea>
      </mat-form-field>
      <div class="validation-errors">
        <div class="error-message" *ngIf="myform.get('responseText').hasError('required') && (myform.get('responseText').dirty || myform.get('responseText').touched)">Text is required field!</div>
      </div>
    </div>

    <div class="form-group">
        <mat-form-field class="example-full-width">
          <input matInput placeholder="Tags" formControlName="tags" >
        </mat-form-field>
      </div>
 
    <br />

    <div class="button-row">
        <button mat-stroked-button type="submit">Save</button> &nbsp;
        <button mat-stroked-button color="warn" (click)="onCloseClick()">Close</button>
    </div>

</form>
