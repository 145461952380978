import { BrowserModule, HammerModule } from '@angular/platform-browser';
import { NgModule, ErrorHandler, APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA  } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { AppComponent } from './app.component';
import { MatLegacySlideToggleModule as MatSlideToggleModule } from '@angular/material/legacy-slide-toggle';
import { HomeComponent } from './home/home.component';
import { LoginComponent } from './auth/login/login.component';
import { RegisterComponent } from './auth/register/register.component';
import { AuthGuard } from './auth/guards/auth.guard';
import { PendingChangesGuard } from './auth/guards/pendingchanges.guard';
import { AlertService } from './service/alert.service';
import { AuthenticationService } from './service/authentication.service';

import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { routing } from './app.routing';
import { ChatService } from './service/chatservice';
import { ChatMessageComponent } from './home/chat-message/chat-message.component';
import { ChatNavBarComponent } from './home/chat-nav-bar/chat-nav-bar.component';
import { ChatWindowComponent } from './home/chat-window/chat-window.component';
import { ChatPageComponent } from './home/chat-page/chat-page.component';
import { FromNowPipe } from './home/pipes/from-now.pipe';
import { PagerService } from './service/pagerservice';
import {ImageCropperModule } from 'ngx-image-cropper';

import { CustomizationService } from './service/customization.service'
import { UsersService } from './service/users.service';
import { RoomsService } from './service/rooms.service';
import { MessagesService } from './service/messages.service';
import { IssueService } from './service/issueservice';
import { TicketService } from './service/ticket.service';
import { ChatRoomComponent } from './home/chat-room/chat-room.component';
import { ChatRoomsComponent } from './home/chat-rooms/chat-rooms.component';
import { ChatRoomMembersComponent } from './home/chat-room-members/chat-room-members.component';
import { ChatRoomMemberComponent } from './home/chat-room-member/chat-room-member.component';
import { ChatRoomNotesComponent } from './home/chat-room-notes/chat-room-notes.component';

import { LogoutComponent } from './auth/logout/logout.component';
import { UserSettings } from './home/user-settings/user-settings.component';
import { GroupByPipe } from './home/pipes/GroupByPipe';
import { NgbModule, NgbNavModule } from '@ng-bootstrap/ng-bootstrap';
import { DistinctUsersPipe } from './home/pipes/distinct-users.pipe';
import { CookieService } from 'ngx-cookie-service';
import { ClientComponent } from './client/client.component';


import { MatLegacyDialogModule as MatDialogModule } from "@angular/material/legacy-dialog";
import {MatLegacyCardModule as MatCardModule} from "@angular/material/legacy-card";
import { MatDatepickerModule } from "@angular/material/datepicker";
import {    MatNativeDateModule } from "@angular/material/core";


import { BrowserAnimationsModule } from "@angular/platform-browser/animations";

import { ConfirmComponent } from './home/confirm/confirm.component';
import { ConfirmInfoComponent } from './home/confirm-info/confirm-info.component';
import { NoticeComponent } from './home/notice/notice.component';
import { ChatPanelComponent } from './home/chat-panel/chat-panel.component';
import { CompHolderDirective } from './home/chat-panel/comp-holder.directive';
import { IframeComponent } from './home/chat-panel/iframe/iframe.component';
import { FormBuilder, Validators, FormGroup, FormControl } from '@angular/forms';

import { APP_BASE_HREF, CommonModule } from '@angular/common';
import { ToastrModule } from 'ngx-toastr';


// import { CustomOption } from './home/CustomOption';

import { TimeDiffPipePipe } from './home/pipes/time-diff-pipe.pipe';
import { ContextMenuModule } from '@perfectmemory/ngx-contextmenu';
import { ChatRoomNotificationsComponent } from './home/chat-room-notifications/chat-room-notifications.component';

import { ChatRoomToBeClosedComponent } from './home/chat-room-to-be-closed/chat-room-to-be-closed.component';
import { ChatCloseFormComponent } from './home/chat-close-form/chat-close-form.component';
import { ChatWindowMembersComponent } from './home/chat-window-members/chat-window-members.component';
import { SafePipe } from './home/pipes/safe.pipe';
import { RoomColorPipe } from './home/pipes/room-color.pipe';
import { UserInfoWindowComponent } from './home/user-info-window/user-info-window.component';
import { RegisterChatBellComponent } from './client/register-chat-bell/register-chat-bell.component';
import { RegisterUserBellComponent } from './client/register-user-bell/register-user-bell.component';
import { TranscriptModalComponent } from './home/transcript-modal/transcript-modal.component';
import { ContactModalComponent } from './home/contact-modal/contact-modal.component';
import { ChatVolumeModalComponent } from './home/chat-volume-modal/chat-volume-modal.component';
import { AllTranscriptsModalComponent } from './home/all-transcripts-modal/all-transcripts-modal.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { ClientFeedbackComponent } from './client/client-feedback/client-feedback.component';
import { ClientWindowComponent } from './client-window/client-window.component';
import { FontAwesomeModule  } from '@fortawesome/angular-fontawesome';
import { AdminComponent } from './admin/admin.component';
import { AdminUsersManagementComponent } from './admin/user-management/user-management.component';
//import { AdminRoomsManagementComponent } from './admin/room-management/room-management.component';
import { AdminMentorSkillMappingComponent } from './admin/mentor-skill-mapping/mentor-skill-mapping.component';
import { AdminSettingsComponnent } from './admin/settings/settings.component';
import { RegisterChatMspComponent } from './client/register-chat-msp/register-chat-msp.component';
import { RegisterUserMspComponent } from './client/register-user-msp/register-user-msp.component';
import { RegisterChatEduComponent } from './client/register-chat-edu/register-chat-edu.component';
import { RegisterUserEduComponent } from './client/register-user-edu/register-user-edu.component';
import { AllTicketsModalComponent } from './home/all-tickets-modal/all-tickets-modal.component';
import { FullScreenImageModalComponent } from './modals/fullscreen-image-modal/fullscreen-image-modal.component';
import { ImageUploadModalComponent } from './image-upload-modal/image-upload-modal.component';

import { MatLegacyPaginatorModule as MatPaginatorModule } from "@angular/material/legacy-paginator";
import { MatLegacyInputModule as MatInputModule } from "@angular/material/legacy-input";
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from "@angular/material/legacy-progress-spinner";
import { MatSortModule } from "@angular/material/sort";
import { MatLegacyTableModule as MatTableModule } from "@angular/material/legacy-table";
import { MatLegacyFormFieldModule as MatFormFieldModule } from "@angular/material/legacy-form-field";
import { MatLegacyButtonModule as MatButtonModule } from "@angular/material/legacy-button";
import { MatLegacyCheckboxModule as MatCheckboxModule } from "@angular/material/legacy-checkbox";
import { MatLegacySelect, MatLegacySelectModule as MatSelectModule } from "@angular/material/legacy-select";
import { MatLegacyTooltipModule as MatTooltipModule } from "@angular/material/legacy-tooltip";
import {MatMenuModule} from "@angular/material/menu";
import { MatSliderModule } from '@angular/material/slider'; 

import { AdminChatUserEditComponent } from './admin/chat-user-edit/chat-user-edit.component';
import { AdminService } from './service/admin.service';
import { AuthGuardAdmin } from './auth/guards/authadmin.guard';
import { AdminMentorAddEditComponent } from './admin/mentor-add-edit/mentor-add-edit.component';
import { AdminMentorsManagementComponent } from './admin/mentor-management/mentor-management.component';
import { PasswordResetComponent } from './auth/passwordreset/passwordreset.component';
import { TypingPipe } from './home/pipes/typing.pipe';
import { TypingLinkifyPipe } from './home/pipes/typing-linkify.pipe';
import { ClientNotFoundComponent } from './client/client-not-found/client-not-found.component';
import { ClientPreviousTicketComponent } from './client/client-previous-ticket/client-previous-ticket.component';
import { AuthGuardClient } from './auth/guards/authclient.guard';
import { DebounceClickDirective } from './directive/debounce-click-directive.directive';
import { AdminVouchersManagement } from './admin/vouchers/vouchers.component';
import { AdminVoucherAddEditComponent } from './admin/voucher-add-edit/voucher-add-edit.component';
import { ChatRoomNotificationComponent } from './home/chat-room-notification/chat-room-notification.component';
//import { DeviceDetectorModule } from 'ngx-device-detector';
import { ChatMessageTranscriptComponent } from './home/chat-message-transcript/chat-message-transcript.component';
import { MaintenanceComponent } from './maintenance/maintenance.component';


import { CacheMapService } from './caching/cache-map.service';

import { CachingInterceptor } from './caching/caching-interceptor';
import { ErrorLogService, GlobalErrorHandler } from './service/error-log.service';
import { LocalizationService } from './service/localization.service';
import { RegisterChatTtiComponent } from './client/register-chat-tti/register-chat-tti.component';
import { MentorSpinnerService } from './service/mentorspinner.service';
import { MsalService } from './service/msal.service';
import { CustomHttpInterceptor } from './auth/helpers/CustomHttpInterceptor';
import { NgxSpinnerModule } from "ngx-spinner";

import { AppConfig } from './AppConfig';
 
import { DatePipe } from '@angular/common';

import {MatIconModule} from '@angular/material/icon';
import { AdminCannedResponsesComponent } from './admin/canned-responses/canned-responses.component';
import { AdminCannedResponsesAddEditComponent } from './admin/canned-responses-add-edit/canned-responses-add-edit.component';
import { AllCannedResponsesModalComponent } from './home/all-cannedresponses-modal/all-cannedresponses-modal.component';


// export function initConfig(config: AppConfig) {
//   const res = () => config.load();
//   return res;
// }

@NgModule({
    declarations: [
        AppComponent,
        HomeComponent,
        LoginComponent,
        RegisterComponent,
        ChatMessageComponent,
        ChatRoomComponent,
        ChatRoomsComponent,
        ChatRoomMembersComponent,
        ChatNavBarComponent,
        ChatWindowComponent,
        ChatPageComponent,
        FromNowPipe,
        GroupByPipe,
        LogoutComponent,
        UserSettings,
        DistinctUsersPipe,
        ClientComponent,
        ConfirmComponent,
        ConfirmInfoComponent,
        NoticeComponent,
        ChatPanelComponent,
        CompHolderDirective,
        IframeComponent,
        TimeDiffPipePipe,
        ChatRoomMemberComponent,
        ChatRoomNotificationsComponent,
        ChatCloseFormComponent,
        ChatWindowMembersComponent,
        ChatRoomToBeClosedComponent,
        SafePipe,
        UserInfoWindowComponent,
        RegisterChatBellComponent,
        RegisterUserBellComponent,
        RoomColorPipe,
        TranscriptModalComponent,
        ContactModalComponent,
        ChatVolumeModalComponent,
        AllTranscriptsModalComponent,
        AllTicketsModalComponent,
        FullScreenImageModalComponent,
        ImageUploadModalComponent,
        ClientFeedbackComponent,
        ClientWindowComponent,
        AdminComponent,
        AdminUsersManagementComponent,
        AdminMentorSkillMappingComponent,
        AdminSettingsComponnent,
        RegisterChatMspComponent,
        RegisterUserMspComponent,
        RegisterChatEduComponent,
        RegisterUserEduComponent,
        AdminChatUserEditComponent,
        AdminMentorAddEditComponent,
        AdminMentorsManagementComponent,
        PasswordResetComponent,
        TypingPipe,
        TypingLinkifyPipe,
        ClientNotFoundComponent,
        ClientPreviousTicketComponent,
        DebounceClickDirective,
        AdminVouchersManagement,
        AdminVoucherAddEditComponent,
        ChatRoomNotificationComponent,
        ChatMessageTranscriptComponent,
        RegisterChatTtiComponent,
        MaintenanceComponent,
        AdminCannedResponsesComponent,
        AdminCannedResponsesAddEditComponent,
        AllCannedResponsesModalComponent
    ],
    imports: [
        MatIconModule,
        BrowserModule,
        HammerModule ,
        BrowserAnimationsModule,
        MatSlideToggleModule,
        FormsModule,
        routing,
        HttpClientModule,
        NgbModule,
        NgbNavModule,
        CommonModule,
        ContextMenuModule,
        ReactiveFormsModule,
        NgxPaginationModule,
        FontAwesomeModule,
        MatTableModule,
        MatPaginatorModule,
        MatSortModule,
        MatProgressSpinnerModule,
        MatFormFieldModule,
        MatInputModule,
        MatButtonModule,
        MatCheckboxModule,
        MatSelectModule,
        MatTooltipModule,
        MatCardModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatDialogModule,
        MatMenuModule,
        MatSliderModule,
        // DeviceDetectorModule.forRoot(),
        ToastrModule.forRoot(),
        NgxSpinnerModule,
        ImageCropperModule 
    ],
    providers: [
        AuthGuard,
        PendingChangesGuard,
        AlertService,
        AuthenticationService,
        ChatService,
        MsalService,
        PagerService,
        MessagesService,
        RoomsService,
        CookieService,
        IssueService,
        TicketService,
        MentorSpinnerService,
        CustomizationService,
        UsersService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: CustomHttpInterceptor,
            multi: true
        },
        CacheMapService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: CachingInterceptor,
            multi: true
        },
        AdminService,
        AuthGuardAdmin,
        AuthGuardClient,
        MatDatepickerModule,
        {
            provide: ErrorHandler,
            useClass: GlobalErrorHandler,
        },
        ErrorLogService,
        LocalizationService,
        AppConfig,
        DatePipe,
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    bootstrap: [AppComponent]
})
export class AppModule { }
