<mat-dialog-actions>
    <button class="mat-raised-button close" (click)="close()"> X </button>
</mat-dialog-actions>
<h2 mat-dialog-title>Canned Responses</h2>

<div mat-dialog-body>

    <div *ngIf='noContentMessage'>{{noContentMessage}}</div>

       <mat-form-field>
        <mat-label>Filter</mat-label>
        <input matInput (keyup)="applyFilter($event)" placeholder="Ex. ium" #input>
      </mat-form-field>

        <mat-table *ngIf="dataSource" [dataSource]="dataSource" class="mat-elevation-z8">
            <ng-container matColumnDef="Description">
                <mat-header-cell *matHeaderCellDef> Description </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.description}}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="Text">
                <mat-header-cell *matHeaderCellDef> Text </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.text}} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="Use in conversation">
                <mat-header-cell *matHeaderCellDef></mat-header-cell>
                <mat-cell *matCellDef="let element">
                    <button mat-stroked-button (click)="ClickOnCannedResponse(element)"> Use in conversation </button> 
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="Copy to clipboard">
                <mat-header-cell *matHeaderCellDef></mat-header-cell>
                <mat-cell *matCellDef="let element">
                     <button mat-stroked-button (click)="ClickOnCopyToClipboard(element)"> Copy to clipboard</button> 

                </mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
        </mat-table>
        <div mat-dialog-actions>
            <mat-paginator [pageSizeOptions]="[10, 20, 50, 100]" showFirstLastButtons></mat-paginator>
        </div>
</div>

