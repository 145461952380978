import { Component, OnInit,ViewChild, ElementRef } from '@angular/core';
import { AdminService } from '../../service/admin.service';
import { CollectionViewer, DataSource } from "@angular/cdk/collections";
import { Observable ,  BehaviorSubject ,  Subject } from 'rxjs';
import { ComponentItem } from '../../home/chat-panel/componentItem';
import { CompHolderDirective } from '../../home/chat-panel/comp-holder.directive';
import { CannedResponse } from '../../model/cannedresponse.model';
import { ConfirmComponent } from '../../home/confirm/confirm.component';
import {MatLegacyDialog as MatDialog,MatLegacyDialogConfig as MatDialogConfig} from "@angular/material/legacy-dialog";
import { MatLegacyPaginator as MatPaginator } from "@angular/material/legacy-paginator";
@Component({
  selector: 'app-admin-canned-responses',
  templateUrl: './canned-responses.component.html',
  styleUrls: ['./canned-responses.component.css']
})

export class AdminCannedResponsesComponent implements OnInit {
  defaultPageNumber: number = 1;
  defaultPageSize: number = 10;
  defaultSearchCriteria: string = "";
  allItems: any[] = [];
  perPage: number = 10;
  fullLength: number;
  currentPage: number;
  totalPages: number;
  public userId: string;
  dataSource: CannedResponsesDataSource;
  displayedColumns = ["id", "description","text","tags", "edit", "delete"];

  @ViewChild(MatPaginator,{static:false}) paginator: MatPaginator;
  @ViewChild('input',{static:false}) input: ElementRef;
  @ViewChild(CompHolderDirective,{static:true}) adHost: CompHolderDirective;

  menuComponents: ComponentItem[];
  public showParentComponent: Boolean = true;
  public showChildComponent: Boolean;
  private searchCriteria$ = "";
  public showSpinner: Boolean = true;

  constructor(private adminService: AdminService, private dialog: MatDialog){
    
  }

  ngOnInit() {
    this.ngOnInitPage();
    this.showSpinner = false;
  }

  ngOnInitPage() {
    this.dataSource = new CannedResponsesDataSource(this.adminService);
    this.dataSource.loadCannedResponses(this.defaultPageNumber, this.defaultPageSize, this.defaultSearchCriteria);
  }

  onSearchClick(searchCriteria: string) {
    this.showSpinner = true;

    if (searchCriteria) {
      this.searchCriteria$ = searchCriteria;
    } else {
      this.searchCriteria$ = "";
    }

    this.countCannedResponses(this.searchCriteria$);
    this.dataSource.loadCannedResponses(this.defaultPageNumber, this.defaultPageSize, this.searchCriteria$);
    this.showSpinner = false;
  }

  countCannedResponses(searchCriteria: string) {
    this.adminService.CountCannedResponses(searchCriteria).subscribe(data => {
      this.fullLength = data;
    });
  }

  onDeleteClick(cannedResponseId:any){
    this.openDialogDelete(cannedResponseId);
   }

   openDialogDelete(cannedResponseId:any) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    var warningMessage = "Are you sure you want to delete the canned response?";

    const dialogRef = this.dialog.open(ConfirmComponent, {
      height: '300px',
      width: '400px',
      data: { warningMessage: warningMessage}
    });

    dialogRef.afterClosed().subscribe(
      result => {
        if (result) {
          this.showSpinner = true;
          this.adminService.DeleteCannedResponse(cannedResponseId).subscribe(data => {
            this.countCannedResponses(this.defaultSearchCriteria);
            this.dataSource.loadCannedResponses(this.defaultPageNumber, this.defaultPageSize, this.defaultSearchCriteria);
            this.showSpinner = false;   
          });
        }
      }
    );
  }

  
  onPageChanged(paginator: any) {
    this.dataSource.loadCannedResponses(paginator.pageIndex + 1, paginator.pageSize, this.searchCriteria$);
  }

  
}

export class CannedResponsesDataSource implements DataSource<any> {
  private cannedResponsesSubject = new BehaviorSubject<CannedResponse[]>([]);
  constructor(private adminService: AdminService) { }

  connect(collectionViewer: CollectionViewer): Observable<CannedResponse[]> {
    return this.cannedResponsesSubject.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer) {
    this.cannedResponsesSubject.complete();
  }

  loadCannedResponses(pageIndex: number, pageSize: number, searchCriteria: string) {
    this.adminService.GetCannedResponses(pageIndex, pageSize, searchCriteria).subscribe(data => {
      this.cannedResponsesSubject.next(data);
    });
  }
}


