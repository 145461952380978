<div>
    <div>
            <br/>
            <div>
              <mat-form-field>
                  <input #searchCannedresponses matInput placeholder="Search canned responses" alt="By name, description or tags" title="By name, description or tags">                  
              </mat-form-field>
              <div class="button-row" style="display: inline-block">
                 <a>
                     <button mat-stroked-button (click)="onSearchClick(searchCannedresponses.value)">Search</button>
                 </a>
              </div>
              <div class="button-row" style="float:right;">
                     <a routerLink="./add">
                         <button  mat-stroked-button>Create New</button>
                     </a>
             </div>
            </div>
 
             <br />

             <mat-spinner *ngIf="showSpinner == true" color="warn" mode="indeterminate" style="margin: 0 auto !important;">
                     
             </mat-spinner>
             
             <mat-table [dataSource]="dataSource">
                     <ng-container matColumnDef="id">
                     <mat-header-cell *matHeaderCellDef> ID </mat-header-cell>
                     <mat-cell *matCellDef="let response"> {{response.id}} </mat-cell>
                     </ng-container>
                     <!-- <ng-container matColumnDef="name">
                     <mat-header-cell *matHeaderCellDef> Name </mat-header-cell>
                     <mat-cell *matCellDef="let response"> {{response.name}} </mat-cell>
                     </ng-container> -->
                     <ng-container matColumnDef="description">
                     <mat-header-cell *matHeaderCellDef> Description </mat-header-cell>
                     <mat-cell *matCellDef="let response"> {{response.description}} </mat-cell>
                     </ng-container>
                     <ng-container matColumnDef="text">
                     <mat-header-cell *matHeaderCellDef> Text </mat-header-cell>
                     <mat-cell *matCellDef="let response"> {{response.text}} </mat-cell>
                     </ng-container>
                     <ng-container matColumnDef="tags">
                     <mat-header-cell *matHeaderCellDef> Tags </mat-header-cell>
                     <mat-cell *matCellDef="let response"> {{response.tags}} </mat-cell>
                     </ng-container>
                     <ng-container matColumnDef="edit">
                             <mat-header-cell *matHeaderCellDef></mat-header-cell>
                             <mat-cell *matCellDef="let response">  
                                 <a routerLink="./edit/{{response.id}}">
                                         <button  mat-stroked-button>
                                                 Edit
                                         </button>
                                 </a>
                             </mat-cell>
                     </ng-container>

                     <ng-container matColumnDef="delete">
                         <mat-header-cell *matHeaderCellDef></mat-header-cell>
                         <mat-cell *matCellDef="let response">  
                             <a (click)="onDeleteClick(response.id)"><button  mat-stroked-button>Delete</button>
                             </a>
                         </mat-cell>
                     </ng-container>

                     <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                     <mat-row *matRowDef="let row; columns: displayedColumns;">
                     </mat-row>
              </mat-table>
              <mat-paginator #paginator (page)="onPageChanged($event)" [pageSizeOptions]="[10,20,50,100]" [pageSize]="10" [length]="fullLength">
              </mat-paginator>
     </div>
 </div>

<div>
<router-outlet></router-outlet>
</div>
