<div class="chat-window-container" >

  <div class="chat-main-window">
    <div class="panel-container">
      <div class="panel panel-default">

        <div class="panel-heading top-bar" style.background-color="{{connectionIndicator}}">
          <div class="panel-title-container" >
            <h3 [matTooltip]="currentRoom.name" matTooltipPosition="below" class="panel-title">
              Session: {{currentRoom.name}}
            </h3>
            <span class="input-group-btn close-btn col-md-2 col-sm-2 col-lg-2" *ngIf="currentRoom.name !== 'Lobby' && isLeaveRoomAllowed">
              <button data-tooltip="End chat" class="btn-close endchatbtn" (click)="onCloseChat($event)">
                 <img src="assets/images/end-chat.png"  /> 
              </button>
            </span>
          </div>
          <div class="panel-buttons-container">

          </div>
        </div>

        <div #msgHistory id="messageContainerID"  (scroll)="onScroll($event)" class="panel-body msg-container-base">
          <div class="loading-indicator" *ngIf="showSpinner == true">
            <mat-spinner  color="primary" [diameter]="40"  mode="indeterminate" >
            </mat-spinner>
          </div>
          <chat-message [events]="focusElementSubject.asObservable()" (sendChangeSignal)="triggerChange($event)" id="messageID" *ngFor="let message of messagesInCurrentRoom | async; trackBy:trackByMessageID;" [message]="message">
          </chat-message>
        </div>
        <div class="msg-is-typing-outer">
          <div class="msg-is-typing">
            <p> {{typingUsrs | typing :localLang}}</p>
          </div>
        </div>



      <div class="chat-footer">
        <div class="attachmentsWrapper position-relative" *ngIf="hasMessageAttachments">
          <div class="mb-2">
            <div *ngFor="let attachment of messageAttachments | async">
              <div class="attachmentHolder">
                <mat-icon class="" fontIcon="image"></mat-icon>
                <a [href]="" target="_blank">{{attachment.FileName}}</a>
                <button mat-icon-button type="button" (click)="removeElementToObservableArray(attachment);" class="cancel">
                  <mat-icon>close</mat-icon>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="panel panel-footer">
          
          <div class="col-md-3 col-lg-3 col-sm-3 col-xs-4 panel-footer-left" *ngIf="showSendSurveyButton">
            <!--  <p>Alias</p>
          <input type="text" class="form-control" [(ngModel)]="nickName" (input)="SetMsgNickname($event.target.value)">-->
            <button class="dropdown-item private-chat" *ngIf="showSendSurveyButton" [ngStyle]="{'background-color': isMessagePrivate? '#ffbf99' : '#0061AA'}"
                    (click)="SetMsgType()">
              {{isMessagePrivate ? 'Turn on Public':'Turn on Private'}}

            </button>
            <!-- <button title="Sending the survey will automatically remove the user from the chat." *ngIf="showSendSurveyButton" class="dropdown-item send-survey"
                    (click)="SendSurvey()">
              Send Survey
            </button> -->

            <button class="dropdown-item private-chat" (click)="LoadCannedResponses()" style="background-color:rgb(0, 97, 170);">
              Load Canned Responses
            </button>
          </div>

          <div [ngClass]="{
              'col-md-9 col-lg-9 col-sm-9 col-xs-8 panel-footer-right':showSendSurveyButton,
              'col-md-12 col-lg-12 col-sm-12 panel-footer-right':!showSendSurveyButton
            }" style="padding:0;">
            <!--
          </div>
          <div  class="col-md-9 col-lg-9 col-sm-9 panel-footer-right"> -->
            
            <div class="panel-footer">
              
              <div class="input-group">



                <textarea class="chat-input" #textInput placeholder="Type your message..." (keydown.enter)="onEnter($event)" [value]="draftMessage.text" cols="200"></textarea>


              </div>
              <div class="chat-buttons">
                <span class="input-group-btn">
                  <button class="btn-chat" (click)="onEnter($event)">Send Message</button>
                </span>
                <span class="input-group-btn" style="right: 155px;  float: right;">
                  <button class="btn-chat" (click)="uploadImage($event)">
                    Upload Image</button>
                </span>
              </div>
            </div>

          </div>
        </div>

      </div>
    </div>
  </div>
  </div>
</div>