export class CannedResponse {
    id: string;
    name: string;
    description:string;
    tags: string;
    text: string;

    constructor(obj?: any) {
        this.id = obj && obj.id || '-1';
        this.name = obj && obj.name || "";
        this.description = obj && obj.description || "";
        this.tags = obj && obj.tags || "";
        this.text = obj && obj.text || "";
    }

    public static Create(cannedResponseVM: any): CannedResponse {
        
        const obj: CannedResponse = new CannedResponse({
            id: cannedResponseVM.Id,
            name: cannedResponseVM.Name,
            description: cannedResponseVM.Description,
            tags: cannedResponseVM.Tags,
            text: cannedResponseVM.Text      
        });

        return obj;
    }
}
