import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges, SimpleChange } from '@angular/core';
import { AdminService } from '../../service/admin.service';
import { ActivatedRoute, Router } from '@angular/router';
import { CannedResponse } from '../../model/cannedresponse.model';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-admin-canned-responses-add-edit',
  templateUrl: './canned-responses-add-edit.component.html',
  styleUrls: ['./canned-responses-add-edit.component.css']
})

export class AdminCannedResponsesAddEditComponent implements OnInit {
  cannedResponse: CannedResponse;
  disableSaveButton: Boolean;

  myform: UntypedFormGroup = new UntypedFormGroup({
    // name: new UntypedFormControl('', [Validators.required]),
    description: new UntypedFormControl('', [Validators.required]),
    responseText: new UntypedFormControl('', [Validators.required]),
    tags: new UntypedFormControl(''),
  });

  constructor(
    private adminService: AdminService,
    private route: ActivatedRoute,
    private router: Router) {

    if (this.route.snapshot.paramMap.get("id") != null) {
      this.getCannedResponseDetails(this.route.snapshot.paramMap.get("id"));

    } else {
      this.cannedResponse = new CannedResponse();
    }
  }

  ngOnInit() { }

  getCannedResponseDetails(id: any): void {
    this.adminService.GetCannedResponseById(id).subscribe(data => {
      if (data) {
        this.cannedResponse = data[0];

        this.myform.setValue({
          // name: this.cannedResponse.description,
          description: this.cannedResponse.description,
          responseText: this.cannedResponse.text,
          tags: this.cannedResponse.tags,
        });
      } else {
        this.router.navigateByUrl('admin/cannedresponses'); // no messing with query parameters!
      }
    });
  }

  onCloseClick() {
    this.router.navigateByUrl('admin/cannedresponses');
  }

  onSaveClick(model: any) {

    if (this.myform.invalid) {
      return;
    }

    this.disableSaveButton = true;

    var newCannedResponse = new CannedResponse();

    newCannedResponse.id = this.cannedResponse.id;
    newCannedResponse.name = model.description;
    newCannedResponse.description = model.description;
    newCannedResponse.text = model.responseText;
    newCannedResponse.tags = model.tags;

    if (this.cannedResponse.id != "-1") {
      this.adminService.UpdateCannedResponse(newCannedResponse)
        .subscribe(data => {
          this.disableSaveButton = false;
          alert("Changes successfuly saved!");
        },
          error => {
            this.disableSaveButton = false;
            console.log();
            alert(error.error);
          });
    }
    else {
      this.adminService.CreateCannedResponse(newCannedResponse)
        .subscribe(data => {
          this.disableSaveButton = false;
          alert("Successfuly saved!");
          this.router.navigateByUrl('admin/cannedresponses');
        },
          error => {
            this.disableSaveButton = false;
            console.log(error.error);
            alert(error.error);
          });
    }
  }
}

