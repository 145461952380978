import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef
} from "@angular/material/legacy-dialog";
import {MatLegacyTableDataSource as MatTableDataSource} from "@angular/material/legacy-table";
import { MatLegacyPaginator as MatPaginator } from "@angular/material/legacy-paginator";
import {
  Component,
  Inject,
  OnInit,
  OnDestroy,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import {Subject} from 'rxjs';
import { AdminService } from "../../service/admin.service";
import { CannedResponse } from "../../model/cannedresponse.model";
import { ChatService } from "../../service/chatservice";
import {Clipboard} from '@angular/cdk/clipboard';

@Component({
  selector: 'app-all-cannedresponses-modal',
  templateUrl: './all-cannedresponses-modal.component.html',
  styleUrls: ['./all-cannedresponses-modal.component.css'],
})

export class AllCannedResponsesModalComponent implements OnInit, OnDestroy {
  allItems: Subject<any> = new Subject<any>();
  cannedResponses: any;
  selectedUserId: any;
  getMore: number = 0;
  displayedColumns = ["Description", "Text", "Use in conversation", "Copy to clipboard"];
  dataSource: any;
  noContentMessage: string = null;
  private onDestroy$: Subject<void> = new Subject<void>();
  @ViewChild(MatPaginator,{static:false}) paginator: MatPaginator;

  constructor(
    private dialogRef: MatDialogRef<AllCannedResponsesModalComponent>, 
    public adminService: AdminService,
    @Inject(MAT_DIALOG_DATA) data, 
    public viewContainerRef: ViewContainerRef,
    public chatService: ChatService,
    public clipBoard: Clipboard) {
    
    const subAllItems = this.allItems.subscribe(items => {
      if (typeof (data.cannedResponses) != 'string') {
        this.dataSource = new MatTableDataSource(data.cannedResponses);
        setTimeout(() => this.dataSource.paginator = this.paginator);
      } else {
        this.noContentMessage = data.cannedResponses;
      }
    });

    this.cannedResponses = data.cannedResponses;
    this.allItems.next(data);
  }

  ngOnInit() {
  }

  save() {
    this.dialogRef.close(true);
  }

  close() {
    this.dialogRef.close(false);
  }

  public ngOnDestroy(): void {
    this.close();
    this.onDestroy$.next();
  }

  ClickOnCannedResponse(cannedResponse: CannedResponse) {
    this.chatService.setCurrentChatMessage(cannedResponse.text);
  }

  ClickOnCopyToClipboard(cannedResponse: CannedResponse) {
    this.clipBoard.copy(cannedResponse.text);
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
}
