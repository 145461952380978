
import { fromEvent as observableFromEvent, Observable, Subject, BehaviorSubject, ReplaySubject, timer, SubscriptionLike as ISubscription, from, of } from 'rxjs';

import { first, takeUntil, debounceTime, throttleTime, bufferTime, map, take, toArray, mergeMap, tap, concatMap } from 'rxjs/operators';
import {
  Component,
  Inject,
  ElementRef,
  OnInit,
  ChangeDetectionStrategy,
  ViewContainerRef,
  Input,
  NgZone,
  ChangeDetectorRef,
  ApplicationRef,
  ViewChild,
  HostListener,
  OnDestroy
} from '@angular/core';


import { UsersService } from '../../service/users.service';
import { ChatService } from '../../service/chatservice';
import { Room } from '../../model/room.model';
import { User } from '../../model/user';
import { RoomsService } from '../../service/rooms.service';
import { Message } from '../../model/message.model';
import { MessagesService } from '../../service/messages.service';
//import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
// import { ToastsManager } from 'ng2-toastr/ng2-toastr';
import { ToastrService } from 'ngx-toastr';
import { RoomFactory } from '../../model/RoomFactory';
import { UserFactory } from '../../model/userFactory';
import { MessageType } from '../../model/messageType';
import { Howl, Howler } from 'howler';
import { MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacyTooltip as MatTooltip } from '@angular/material/legacy-tooltip';
import { ConfirmComponent } from '../confirm/confirm.component';
import { ActivatedRoute } from '@angular/router';
import { LocalizationService } from '../../service/localization.service';
import * as _ from 'lodash';
import { SignalRStatus } from '../../model/SignalRStatus';

import { MentorSpinnerService } from '../../service/mentorspinner.service';
import { ImageUploadModalComponent } from '../../image-upload-modal/image-upload-modal.component';

import { TicketService } from '../../service/ticket.service';
import { HttpErrorResponse } from '@angular/common/http';
import { uuid } from '../util/uuid';
import { DomSanitizer } from '@angular/platform-browser';
import { MatIconModule } from '@angular/material/icon';
import { MessageAttachment } from '../../model/MessageAttachment.model';
import { messageFactory } from '../../model/messageFactory';
import { NgxSpinnerService } from 'ngx-spinner';
import { AdminService } from '../../service/admin.service';
import { MatDialogRef } from '@angular/material/dialog';
import { AllTicketsModalComponent } from '../all-tickets-modal/all-tickets-modal.component';
import { AllCannedResponsesModalComponent } from '../all-cannedresponses-modal/all-cannedresponses-modal.component';



@Component({
  selector: 'chat-window',
  templateUrl: './chat-window.component.html',
  styleUrls: ['./chat-window.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChatWindowComponent implements OnInit, OnDestroy {

  private onDestroy$: ReplaySubject<boolean> = new ReplaySubject(1);
  @Input() data: any;

  messagesInCurrentRoom: Observable<any>;
  currentRoom: Room;
  isLobbyRoom: boolean;
  draftMessage: Message;
  currentUser: User;
  isMessagePrivate: boolean = false;
  prevMsgsAreLoaded: boolean = false;
  nickName: string = '';
  public isLoadingPrevMsgs: boolean = false;;
  private subjectKeyDown: Subject<any> = new Subject<any>();
  public keyUp = new Subject<string>();

  typingUsrs: Array<User> = [];
  typingMessage: string = "";
  showSendSurveyButton: boolean;
  imgUrl: any;

  roomMembers: Observable<User[]>;
  connectionIndicator: string = "#EEEEEE";
  isLeaveRoomAllowed: boolean = true;
  lastMsgId: string = "";

  lastScrollHeight: any;
  currScrollTop: any;

  public isLoadingPrevMsgsActive: boolean = false;
  timerLoadPrevMsgs: any = null;
  timelaps: any = new Date().getTime();
  @ViewChild('textInput', { static: false }) textInput: ElementRef;
  @ViewChild('msgHistory', { static: false }) msgHistory: ElementRef;
  localLang: any = "en";
  public showSpinner: Boolean = true;
  focusElementSubject: Subject<string> = new Subject<string>();
  isGetMsgInProgress: Boolean = false;
  messageAttachmentSubject: BehaviorSubject<MessageAttachment[]> = new BehaviorSubject<MessageAttachment[]>([]);
  messageAttachments: Observable<MessageAttachment[]> = this.messageAttachmentSubject.asObservable();
  messageAttachmentsArray: MessageAttachment[] = [];
  hasMessageAttachments: boolean = false;
  cannedResponsesDialogRef: MatDialogRef <any> ;

  constructor(public messagesService: MessagesService,
    public roomsService: RoomsService,
    public UsersService: UsersService,
    public chatService: ChatService,
    public ticketService: TicketService,
    public toastr: ToastrService, vcr: ViewContainerRef,
    public el: ElementRef,
    private ngZone: NgZone,
    private dialog: MatDialog,
    private activatedRoute: ActivatedRoute,
    private localizationService: LocalizationService,
    private changeDetector: ChangeDetectorRef,
    private spinnerService: MentorSpinnerService,
    private sanitizer: DomSanitizer,
    private spinnerServiceDialogs: NgxSpinnerService,
    private adminService: AdminService) {



    this.roomMembers = roomsService.currentRoomMembers;


    const keyUpEvent = observableFromEvent(el.nativeElement, 'keyup');

    const result = keyUpEvent.pipe(takeUntil(this.onDestroy$)).pipe(throttleTime(4000)).subscribe(x => {

      this.chatService.StartTyping(this.currentRoom.id, this.isMessagePrivate);
    });

    this.lastScrollHeight = 0;

    this.messagesInCurrentRoom = this.roomsService.currentRoomMessages.pipe(takeUntil(this.onDestroy$), map(msgs => {
      //this.roomsService.currentRoomMsgLoaded.next();
      return _.cloneDeep(_.sortBy(msgs, (t: Message) => t.sentAt));
      //return msgs;
    }),);

    this.isGetMsgInProgress = false;
  }




  ngOnInit(): void {


    this.showSpinner = false;
    //this.messagesInCurrentRoom = this.roomsService.currentRoomMessages;

    this.draftMessage = new Message();
    this.draftMessage.text = '';

    this.chatService.GetPreviousMessagesInProgres.pipe(takeUntil(this.onDestroy$)).subscribe((inProgress) => {
      this.isGetMsgInProgress = inProgress;
    });

    const subscriptionNewNotification = this.messagesService.newMessageNotification.pipe(takeUntil(this.onDestroy$)).pipe(debounceTime(1000)).subscribe((roomGuid) => {
      const scrollPane: any = this.el
        .nativeElement.querySelector('.msg-container-base');

      //if bottom
      //if (scrollPane.offsetHeight + scrollPane.scrollTop + scrollPane.scrollHeight * 0.2 >= scrollPane.scrollHeight) {
      setTimeout(() => {
        this.scrollToBottom();
      });
      //}
      this.ngZone.run(() => {
        if (this.currentRoom.id == roomGuid) {
          var userVolume = 100;

          if (localStorage.getItem("currentUserChatVolume")) {
            userVolume = parseInt(localStorage.getItem("currentUserChatVolume"));
          }

          var sound = new Howl({
            src: ['assets/audio/definite.mp3'],
            volume: userVolume / 100
          });

          sound.play();
        }
      });
    });

    const subscriptionCurrentRoom = this.roomsService.currentRoom.pipe(
      takeUntil(this.onDestroy$))
      .subscribe(
        (room: Room) => {
          this.currentRoom = room;
          this.isMessagePrivate = false;
          this.draftMessage = new Message();
          this.draftMessage.text = '';
          this.prevMsgsAreLoaded = false;
          if (this.currentRoom.name !== 'Lobby') {
            this.showSendSurveyButton = true;
            this.isLobbyRoom = false;
          } else {
            this.isLobbyRoom = true;
            this.showSendSurveyButton = false;
          }
        });

    const subscriptionCurrentUser = this.UsersService.currentUser.pipe(takeUntil(this.onDestroy$))
      .subscribe(
        (user: User) => {
          this.currentUser = user;
        });


    const subscriptionRoomMsgLoaded = this.roomsService.currentRoomMsgLoaded.pipe(takeUntil(this.onDestroy$))
      .subscribe({
        next: (roomArr: string[]) => {

          if (this.currentRoom.id == roomArr[0]) {
            const scrollPane: any = this.el
              .nativeElement.querySelector('.msg-container-base');
            const msgPane: any = this.el
              .nativeElement.querySelector('.messages-received');

            this.chatService.GetPreviousMessagesInProgres.next(false);
            this.lastMsgId = roomArr[1];
            //if bottom
            if (roomArr[1] === '-1' || roomArr[1] === '0') {
              //if (scrollPane.offsetHeight + scrollPane.scrollTop + scrollPane.scrollHeight * 0.2 >= scrollPane.scrollHeight) {
              setTimeout(() => {
                this.scrollToBottom();
              }, 1000);
              //}
            } else {
              this.focusElementSubject.next(roomArr[1]);
            }

          }
        }
      })

    const subscriptionNotification = this.messagesService.typingNotification.pipe(takeUntil(this.onDestroy$)).subscribe((usr) => {

      if (usr === null) return;

      if (this.typingUsrs.findIndex(s => s.name == usr.name) === -1) {

        this.changeDetector.markForCheck();
        this.typingUsrs.push(usr);


        clearTimeout(timer);
        var timer = setTimeout(() => {
          var index = this.typingUsrs.findIndex(s => s.name == usr.name);
          if (index !== -1) {
            this.changeDetector.markForCheck();
            this.typingUsrs.splice(index, 1);

          }
        }, 6000);

      }
    });

    const subscriptionNotificationCancel = this.messagesService.typingNotificationCancel.pipe(takeUntil(this.onDestroy$))
      .subscribe((usr) => {
        if (usr === null) return;

        var index = this.typingUsrs.findIndex(s => s.name == usr.name);
        if (index !== -1) {

          this.changeDetector.markForCheck();
          this.typingUsrs.splice(index, 1);

        }
      });

    const subConnectSignalR = this.chatService.connectionSubject.pipe(takeUntil(this.onDestroy$)).subscribe((val) => {
      switch (val) {
        case SignalRStatus.Connected:
          this.connectionIndicator = "#EEEEEE";
          break;
        case SignalRStatus.Disconnected:
          this.connectionIndicator = "#ff0000";
          break;
        case SignalRStatus.Reconnectiong:
          this.connectionIndicator = "#2a94db";
          break;
      }
    });

    const subRemoveEndUser = this.chatService.removingEndUserSubject.pipe(takeUntil(this.onDestroy$)).subscribe((val) => {
      this.isLeaveRoomAllowed = !val;
    });

    var localeFromUrl = this.activatedRoute.snapshot.queryParams["lang"];
    var locale = this.localizationService.ValidateLocale(localeFromUrl);
    this.localLang = locale;

    this.chatService.chatMessageToSendSubject.subscribe((chatMessage: string) => {
        if(chatMessage !== undefined && chatMessage.length >0){
          this.textInput.nativeElement.value = chatMessage;
        }
    });
  }


  public triggerChange(valueEmitted: any) {
    this.changeDetector.markForCheck();
  }


  public handleChange(value) {
    this.draftMessage.text = value;
  }

  scrollToBottom(): void {
    const scrollPane: any = this.el.nativeElement.querySelector('.msg-container-base');

    scrollPane.scrollTop = scrollPane.scrollHeight;
  }

  onScroll(event: any): void {

    this.lastScrollHeight = event.target.scrollHeight;

    if (event.target.scrollTop == 0) {

      this.messagesInCurrentRoom.pipe(first(), takeUntil(this.onDestroy$),).subscribe(rez => {


        if (this.timerLoadPrevMsgs == null && !this.isGetMsgInProgress && rez.length > 0) {
          //var lastMsgId=this.messagesService.getLastMessageForTheRoom(this.currentRoom.id);
          // console.log("Last message Id: "+ this.lastMsgId);
          // console.log("Last Id: "+rez[0].id);
          if (this.lastMsgId !== null && this.lastMsgId === rez[0].id) {
            return;
          }


          this.showSpinner = true;
          this.timerLoadPrevMsgs = setTimeout(function () {
            console.log("Get Prev MSgs");
            //TODO: show loading indicator 

            //TODO: last message - must be in the room
            this.timerLoadPrevMsgs = null;
            //this.chatService.getPrevMessages(this.currentRoom.id, true, rez[0].id).pipe(takeUntil(this.onDestroy$))
            this.chatService.getPrevMessagesChain(this.currentRoom.id, true, rez[0].id).pipe(takeUntil(this.onDestroy$)).subscribe(() => {
              this.showSpinner = false;
              this.changeDetector.markForCheck();
            }, error => {
              this.showSpinner = false;
              this.changeDetector.markForCheck();
            });


          }.bind(this), 200);
        }
      });

      this.isLoadingPrevMsgs = true;
      //clear load prev notification if no older messages
      setTimeout(function () {
        this.ngZone.run(() => this.isLoadingPrevMsgs = false);
        this.isLoadingPrevMsgs = false;
        // this.showSpinner = false;
        // this.changeDetector.markForCheck();
      }.bind(this), 3000);

    }
  }

  onEnter(event: any): void {
    this.sendMessage(this.textInput.nativeElement.value);
    this.textInput.nativeElement.value = '';

    event.preventDefault();
  }

  uploadImage(event: any) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    const dialogRef = this.dialog.open(ImageUploadModalComponent, {
      // height: '700px',
      height: 'auto',
      width: '600px',
      data: {}
    });

    const subDialogRef = dialogRef.afterClosed().pipe(takeUntil(this.onDestroy$)).subscribe(
      val => {
        if (val !== null && val != undefined && val) {
          if (val.length === 0) {
            return;
          }
          let fileToUpload = <File>val;
          const formData = new FormData();
          var fileName = fileToUpload.name;

          formData.append('file', fileToUpload, uuid() + '.png');
          formData.append('roomId', this.currentRoom.id);
          formData.append('userId', this.currentUser.id);
          formData.append('fileName', fileName);
          //formData.append('roomId',this.currentRoom.id);

          this.spinnerService.ShowSpinner();
          this.ticketService.uploadImage(formData).pipe(takeUntil(this.onDestroy$))
            .subscribe(result => {
              this.spinnerService.HideSpinner();
              if (!result.HasError) {
                var attachment = new MessageAttachment(
                  {
                    ImageId: result.ImageUrl,
                    ImageUri: result.ImageUrl,
                    ThumbnailUri: result.ThumbUrl,
                    ContainerName: result.ContainerName,
                    FileName: fileName,
                    MessageId: '',
                    UserId: this.currentUser.id
                  });
                //this.addElementToObservableArray(attachment);
                this.messageAttachmentsArray.push(attachment);
                this.sendMessage('');
              }

            },
              error => {
                this.spinnerService.HideSpinner();
                this.UsersService.clientTostrErrorNotification.next("Error while uploading the image to chat. Please refresh the page and try again.");
              });

        } else if (val !== null && val != undefined && val == false) {

        } else {

        }
      }
    );
    dialogRef.disableClose = false;
  }

  addElementToObservableArray(item) {
    this.messageAttachments.pipe(take(1), takeUntil(this.onDestroy$)).subscribe(val => {
      console.log(val)
      const newArr = [...val, item];
      this.messageAttachmentsArray = newArr;

      if (this.messageAttachmentsArray.length > 0)
        this.hasMessageAttachments = true;
      else
        this.hasMessageAttachments = false;

      this.changeDetector.markForCheck();
      this.messageAttachmentSubject.next(newArr);
    })
  }

  removeAllArray() {
    this.messageAttachments.pipe(take(1), takeUntil(this.onDestroy$)).subscribe(val => {
      //const arr = this.messageAttachmentSubject.getValue()
      const newArr = [];
      this.messageAttachmentsArray = newArr;
      if (this.messageAttachmentsArray.length > 0)
        this.hasMessageAttachments = true;
      else
        this.hasMessageAttachments = false;


      this.messageAttachmentSubject.next(newArr)
    })
  }

  removeElementToObservableArray(item) {
    this.messageAttachments.pipe(take(1), takeUntil(this.onDestroy$)).subscribe(val => {
      const newArr = this.messageAttachmentSubject.getValue()
      newArr.splice(item, 1);

      this.messageAttachmentsArray = newArr;
      if (this.messageAttachmentsArray.length > 0)
        this.hasMessageAttachments = true;
      else
        this.hasMessageAttachments = false;

      this.messageAttachmentSubject.next(newArr)
    })
  }

  onCloseChat(event: any): void {
    this.chatService.closeRoom(this.currentRoom);
    event.preventDefault();
  }

  sendMessage(value: string): void {
    if (value.match(/^\s*$/) && this.messageAttachmentsArray.length == 0) {
      return;
    }
    if (value == null) return;
    if (value === '' && this.messageAttachmentsArray.length == 0) return;
    if (value.length <= 0 && this.messageAttachmentsArray.length == 0) return;
    value = value.replace("<", "< ");

    this.draftMessage = new Message();
    this.draftMessage.text = value;

    const m: Message = this.draftMessage;

    m.author = JSON.parse(localStorage.getItem('currAuthor'));
    if (this.nickName !== '')
      m.author.userName = this.nickName;

    m.msgType = m.orgMsgType = MessageType.Default;

    m.room = this.currentRoom;
    m.room.roomStatus = 4;

    if (m.author.isMentor === null)
      m.room.roomStatus = 2;
    else if (m.author.isMentor)
      m.room.roomStatus = 3;
    else if (m.author.isMentor && m.room.name == 'Lobby')
      m.room.roomStatus = 2;

    m.isRead = true;
    m.isPrivate = this.isMessagePrivate;
    if (this.isMessagePrivate)
      m.orgMsgType = MessageType.Private;


    m.messageAttachments = this.messageAttachmentsArray;
    if (m.messageAttachments.length > 0) {
      this.spinnerService.ShowSpinner();
      from(m.messageAttachments).pipe(
        mergeMap(module => this.ticketService.downloadFile(module.ContainerName, module.ThumbnailUri).pipe(
          tap(apiResponse => {
            //let objectURL = URL.createObjectURL(apiResponse);       
            //module.ThumbnailUriUrl = apiResponse;
            let objectURL = URL.createObjectURL(apiResponse);
            module.ThumbnailUriUrl = objectURL;
          })
        )),
        toArray()
      ).pipe(takeUntil(this.onDestroy$)).subscribe(allResponses => {
        this.spinnerService.HideSpinner();
        console.log(allResponses);
        this.chatService.sendMessage(m);
        this.messageAttachmentsArray = [];
        this.draftMessage = new Message();
        this.draftMessage.text = '';
        setTimeout(() => {
          this.scrollToBottom();
        });
      },
        error => {
          this.spinnerService.HideSpinner();
          this.UsersService.clientTostrErrorNotification.next("Error occured");
        });

    } else {
      this.chatService.sendMessage(m);
      this.draftMessage = new Message();
      this.draftMessage.text = '';
      this.messageAttachmentsArray = [];
      setTimeout(() => {
        this.scrollToBottom();
      });
    }

  }

  SetMsgNickname(nickname: string) {
    this.nickName = nickname;
  }




  SetMsgType(): void {
    this.isMessagePrivate = !this.isMessagePrivate;
  }

  SendSurvey() {
    this.openDialog();
  }

  SendSurveyGo() {
    this.chatService.SendSurveyToUser('', this.currentRoom.id);
  }

  openDialog() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    const dialogRef = this.dialog.open(ConfirmComponent, {
      height: '300px',
      width: '400px',
      data: { warningMessage: "Sending the survey will automatically disconnect the user. Are you sure you want to proceed?" }
    });

    const subDialogRef = dialogRef.afterClosed().pipe(takeUntil(this.onDestroy$)).subscribe(
      result => {
        if (result) {
          this.SendSurveyGo();
        }
      }
    );
  }

  trackByMessageID(index: number, msg: Message): any {
    return msg.sentAt;
  }


  public ngOnDestroy(): void {
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }

  LoadCannedResponses() {
    this.spinnerServiceDialogs.show();
    const cannedResponsesData = this.adminService.GetCannedResponses(1,1000,"").pipe(takeUntil(this.onDestroy$)).subscribe(
      data => {
        this.openAllCannedResponsesDialog(data);
      }, error => {
      this.openAllCannedResponsesDialog(error.error);
    }
    );
  }

  openAllCannedResponsesDialog(cannedResponses) { 
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
     
    dialogConfig.data = { 
      cannedResponses: cannedResponses
    };

    dialogConfig.height = '800px';
    dialogConfig.width = '1200px'; 
    dialogConfig.disableClose=true;
    this.spinnerServiceDialogs.hide();

    this.cannedResponsesDialogRef = this.dialog.open(AllCannedResponsesModalComponent, dialogConfig);

    const subDialogRef = this.cannedResponsesDialogRef.afterClosed().pipe(takeUntil(this.onDestroy$)).subscribe(result => {  
      this.cannedResponsesDialogRef = null;
    });
  }
}
